

const ErrorPage = () =>{
    return(
    <div className="errorpage-area">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-10 ">
                    <div className="errorpage-section d-flex vh-100 flex-column justify-content-center">
                        <div className="text-center">  
                            <div className="text-center errorpage-titles">  
                                <span>                                            
                                    <svg width="80" height="80" viewBox="0 0 153 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.2 23.0236C21.4092 23.0236 23.2 21.2279 23.2 19.0128C23.2 16.7977 21.4092 15.002 19.2 15.002C16.9909 15.002 15.2 16.7977 15.2 19.0128C15.2 21.2279 16.9909 23.0236 19.2 23.0236Z" fill="#DE1A1B"/>
                                        <path d="M30.88 23.0236C33.0891 23.0236 34.88 21.2279 34.88 19.0128C34.88 16.7977 33.0891 15.002 30.88 15.002C28.6709 15.002 26.88 16.7977 26.88 19.0128C26.88 21.2279 28.6709 23.0236 30.88 23.0236Z" fill="#DE1A1B"/>
                                        <path d="M42.4 23.0236C44.6092 23.0236 46.4 21.2279 46.4 19.0128C46.4 16.7977 44.6092 15.002 42.4 15.002C40.1909 15.002 38.4 16.7977 38.4 19.0128C38.4 21.2279 40.1909 23.0236 42.4 23.0236Z" fill="#DE1A1B"/>
                                        <path d="M141.44 0.241211H10.56C4.8 0.241211 0 4.89375 0 10.8298V119.763C0 125.539 4.64 130.352 10.56 130.352H141.6C147.36 130.352 152.16 125.699 152.16 119.763V10.8298C152 5.05418 147.36 0.241211 141.44 0.241211ZM10.56 8.42326H141.6C142.88 8.42326 144 9.54629 144 10.8298V29.6003H8.16V10.8298C8.16 9.54629 9.28 8.42326 10.56 8.42326ZM141.44 122.009H10.56C9.28 122.009 8.16 120.886 8.16 119.603V37.622H143.84V119.603C143.84 120.886 142.72 122.009 141.44 122.009Z" fill="#707070"/>
                                        <path d="M54.24 75.4837L58.72 70.9916L63.2 75.4837C64 76.2859 64.96 76.6068 66.08 76.6068C67.2 76.6068 68.16 76.2859 68.96 75.4837C70.56 73.8794 70.56 71.3125 68.96 69.7082L64.48 65.216L68.96 60.7239C70.56 59.1196 70.56 56.5527 68.96 54.9484C67.36 53.344 64.8 53.344 63.2 54.9484L58.72 59.4405L54.24 54.9484C52.64 53.344 50.08 53.344 48.48 54.9484C46.88 56.5527 46.88 59.1196 48.48 60.7239L52.96 65.216L48.48 69.7082C46.88 71.3125 46.88 73.8794 48.48 75.4837C49.28 76.2859 50.24 76.6068 51.36 76.6068C52.48 76.6068 53.44 76.2859 54.24 75.4837Z" fill="#DE1A1B"/>
                                        <path d="M103.52 55.1095C101.92 53.5052 99.36 53.5052 97.76 55.1095L93.28 59.6016L88.8 55.1095C87.2 53.5052 84.64 53.5052 83.04 55.1095C81.44 56.7138 81.44 59.2807 83.04 60.8851L87.52 65.3772L83.04 69.8693C81.44 71.4736 81.44 74.0405 83.04 75.6449C83.84 76.447 84.8 76.7679 85.92 76.7679C87.04 76.7679 88 76.447 88.8 75.6449L93.28 71.1528L97.76 75.6449C98.56 76.447 99.52 76.7679 100.64 76.7679C101.76 76.7679 102.72 76.447 103.52 75.6449C105.12 74.0405 105.12 71.4736 103.52 69.8693L99.04 65.2168L103.52 60.7246C105.12 59.2807 105.12 56.7138 103.52 55.1095Z" fill="#DE1A1B"/>
                                        <path d="M76 83.5059C64.96 83.5059 55.04 90.0836 50.56 100.191C49.6 102.276 50.56 104.683 52.64 105.485C54.72 106.448 57.12 105.485 57.92 103.4C60.96 96.18 68 91.5275 75.84 91.5275C83.52 91.5275 90.56 96.18 93.6 103.4C94.24 105.004 95.84 105.806 97.28 105.806C97.76 105.806 98.4 105.646 98.88 105.485C100.96 104.523 101.92 102.276 100.96 100.191C96.96 90.0836 87.04 83.5059 76 83.5059Z" fill="#DE1A1B"/>
                                    </svg>
                                </span>
                                <p className="text-gray fs_18 fw_6 ls_1 text-uppercase mt-2">Error</p>
                                <h1 className="fw_4 text-gray fw_6 fs_102 mt-4 line_height_70">404</h1> 
                                <p className="text-gray mt-3 fs_18 fw_4 ls_1 text-uppercase mt-3">PAGE NOT FOUND</p>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ErrorPage;